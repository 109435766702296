import {
  ArrowDownIcon, ArrowDownTrayIcon,
  ArrowUpTrayIcon,
  Bars3Icon,
  MagnifyingGlassPlusIcon,
  PrinterIcon,
  TableCellsIcon,
  XMarkIcon
} from "@heroicons/react/24/outline";
import {
  getCoreRowModel, getFacetedRowModel, getFacetedUniqueValues,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable
} from "@tanstack/react-table";
import {useContext, useMemo, useState} from "react";
import * as React from "react";
import {useSessionStorage} from "react-use";
import DashboardContext from "./Contexts/DashboardContext";
import FilterContext from './Contexts/FilterContext';
import {Button} from "./ui/Button";
import DataTable from "./ui/DataTable";
import DataTableFacetedFilter from "./ui/DataTableFacetedFilter";

import {Area, AreaChart, Bar, BarChart, CartesianGrid, Label, Pie, PieChart, XAxis} from "recharts"
import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
} from "./ui/Card";
import {
  ChartContainer, ChartLegend, ChartLegendContent,
  ChartTooltip,
  ChartTooltipContent,
} from "./ui/Chart";
import {Dialog, DialogClose, DialogContent, DialogFooter, DialogTitle, DialogTrigger} from "./ui/Dialog";
import {Drawer, DrawerContent, DrawerTitle, DrawerTrigger} from "./ui/Drawer";
import {DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuTrigger} from "./ui/Dropdown";
import {classNames} from "./utils/classes";

export const description = "A donut chart with text"

const pieChartConfig2 = {
  visitors: {
    label: "Visitors",
  },
  chrome: {
    label: "Property",
    color: "hsl(var(--chart-1))",
  },
  safari: {
    label: "Workers Comp.",
    color: "hsl(var(--chart-2))",
  },
  firefox: {
    label: "Commercial Liability",
    color: "hsl(var(--chart-3))",
  },
  edge: {
    label: "Professional Liability",
    color: "hsl(var(--chart-4))",
  },
  other: {
    label: "Other",
    color: "hsl(var(--chart-5))",
  },
}


function generateRandom([min, max]) {
  return Math.floor(Math.random() * (max - min + 1)) + min;
}

const graphData = [
  {
    company: "21st Century Casualty Company",
    type: 'Insurance',
    market: "North America",
    lob: "Property",
    year: generateRandom([2021, 2022]),
    segment: 'Casualty & Property',
  },
  {
    company: "AIG",
    type: 'Insurance',
    market: "North America",
    lob: "Workers Comp",
    year: generateRandom([2021, 2022]),
    segment: 'Casualty & Property',
  },
  {
    company: "Chubb",
    type: 'Insurance',
    market: "North America",
    lob: "Commercial Liability",
    year: generateRandom([2021, 2022]),
    segment: 'Casualty & Property',
  },
  {
    company: "Liberty Mutual",
    type: 'Insurance',
    market: "North America",
    lob: "Professional Liability",
    year: generateRandom([2021, 2022]),
    segment: 'Casualty & Property',
  }
];

const tableData = [
  {
    company: "21st Century Casualty Company",
    naic: generateRandom([10000, 99999]),
    state: "CA",
    lob: "ALL",
    dpeUsdM: generateRandom([1, 200]),
    ayIlUsdM: generateRandom([1, 200]),
    ayLr: `${generateRandom([0, 100])}%`,
    brokerage: `${generateRandom([15, 25])}%`
  },
  {
    company: "AIG",
    naic: generateRandom([10000, 99999]),
    state: "CA",
    lob: "ALL",
    dpeUsdM: generateRandom([1, 200]),
    ayIlUsdM: generateRandom([1, 200]),
    ayLr: `${generateRandom([0, 100])}%`,
    brokerage: `${generateRandom([15, 25])}%`
  },
  {
    company: "Chubb",
    naic: generateRandom([10000, 99999]),
    state: "CA",
    lob: "ALL",
    dpeUsdM: generateRandom([1, 200]),
    ayIlUsdM: generateRandom([1, 200]),
    ayLr: `${generateRandom([0, 100])}%`,
    brokerage: `${generateRandom([15, 25])}%`
  },
  {
    company: "Liberty Mutual",
    naic: generateRandom([10000, 99999]),
    state: "CA",
    lob: "ALL",
    dpeUsdM: generateRandom([1, 200]),
    ayIlUsdM: generateRandom([1, 200]),
    ayLr: `${generateRandom([0, 100])}%`,
    brokerage: `${generateRandom([15, 25])}%`
  }
];

function getFacetedData(key, dataSource = tableData) {
  return Object.values(dataSource.reduce((acc, row) => {
    const value = row[key];
    acc[value] ||= {count: 0};
    acc[value] = {...acc[value], value, count: acc[value].count + 1}
    return acc;
  }, {}))
}

export default function Data() {
  const [filterState, setFilterState] = useState({});
  const [graphFilterState, setGraphFilterState] = useState({});
  const {toggleShowSidebar} = useContext(DashboardContext);
  const [selectedValue, setSelectedValue] = useState();
  const [showWelcomeMessage, setShowWelcomeMessage] = useSessionStorage('data-welcome-message', true);

  return (
    <div className='flex flex-col justify-between sticky top-0 z-50 bg-gray-800 text-lg font-semibold py-5 px-4'>
      <Dialog open={showWelcomeMessage} onOpenChange={setShowWelcomeMessage}>
        <DialogContent disableClose className='p-5'>
          <DialogTitle>Fuse Data</DialogTitle>
          <div>
            Fuse Data is currently in preview
          </div>
          <div>
            We're excited to show you what we have in store but please be aware that <strong>all data is randomly
            generated</strong> each time you open Data and features such as <strong>sorting and filter are not
            enabled</strong>.
          </div>
          <DialogFooter>
            <DialogClose>
              <Button>I got it</Button>
            </DialogClose>
          </DialogFooter>
        </DialogContent>
      </Dialog>
      <div className='flex items-center pb-4'>
        <div className='lg:hidden lg:mr-0 mr-3 hidden' onClick={() => toggleShowSidebar()}>
          <span className="sr-only">Open sidebar</span>
          <Bars3Icon role='button' className="h-7 w-7" aria-hidden="true"/>
        </div>
        <div className='flex items-center justify-between w-full'>
          <div className='flex items-center space-x-2'>
            <div>Data</div>
            <div className='bg-purple-500 rounded-full text-xs px-1.5 py-0.5 font-normal'>Preview</div>
          </div>
          <div className='flex items-center space-x-4'>
            <TableCellsIcon className='h-5'/>
            <ArrowUpTrayIcon className='h-5'/>
            <PrinterIcon className='h-5'/>
          </div>
        </div>
      </div>
      <div className='font-normal'>Market overview</div>
      <div className='flex flex-wrap items-center gap-2 mt-2'>
        <FilterContext.Provider value={{
          filterState: graphFilterState,
          setFilterState: (newFilterState) => {
            setGraphFilterState(newFilterState);
          }
        }}>
          <DataTableFacetedFilter columnName='lob' title='LOB' options={getFacetedData('lob', graphData)}/>
          <DataTableFacetedFilter columnName='company' title='Company' options={getFacetedData('company', graphData)}/>
          <DataTableFacetedFilter columnName='type' title='Type' options={getFacetedData('type', graphData)}/>
          <DataTableFacetedFilter columnName='market' title='Market' options={getFacetedData('market', graphData)}/>
          <DataTableFacetedFilter columnName='year' title='Year' options={getFacetedData('year', graphData)}/>
          <DataTableFacetedFilter columnName='segment' title='Segment' options={getFacetedData('segment', graphData)}/>
        </FilterContext.Provider>
        <Button
          variant="ghost"
          className="h-8 px-2 lg:px-3 flex items-center"
          onClick={() => setGraphFilterState({})}
        >
          <span>Reset</span>
          <XMarkIcon className="ml-2 h-4 w-4"/>
        </Button>
      </div>

      <div className='space-y-8'>
        <div className='grid xl:grid-cols-12 md:grid-cols-2 grid-cols-1 gap-8 mt-4'>
          <div className='xl:col-span-3'>
            <PieChartComponent isSelected={selectedValue === 'DPE millions'} setSelectedChart={setSelectedValue} title='DPE millions' />
          </div>
          <div className='xl:col-span-3'>
            <AreaChartComponent isSelected={selectedValue === 'DPE Growth'} setSelectedChart={setSelectedValue} title='DPE Growth' />
          </div>
          <div className='xl:col-span-3'>
            <BarChartComponent isSelected={selectedValue === 'DWP Growth'} setSelectedChart={setSelectedValue} title='DWP Growth' />
          </div>
          <div className='xl:col-span-3'>
            <BarChartComponent isSelected={selectedValue === 'Loss Ratio Trend'} setSelectedChart={setSelectedValue} title='Loss Ratio Trend' minMax={[70, 100]} />
          </div>
        </div>

        <div className='lg:flex justify-between gap-8 sm:grid-cols-2 sm:grid space-y-8 sm:space-y-0'>
          <div className='w-full'>
            <BarChartComponent isSelected={selectedValue === 'Brokerage Trend'} setSelectedChart={setSelectedValue} className='h-[250px] w-full' title='Brokerage Trend' minMax={[15,30]} />
          </div>
          <div className='w-full'>
            <AreaChartComponent isSelected={selectedValue === 'Rate Changes'} setSelectedChart={setSelectedValue} className='h-[250px] w-full' title='Rate Changes' minMax={[0, 5]} />
          </div>
        </div>
      </div>

      <div className='mt-5 font-normal flex items-center justify-between'>
        <span>Market performance</span>
        <div className='sm:hidden'>
          <DropdownMenu>
            <DropdownMenuTrigger>
              <div className='flex items-center text-sm font-normal space-x-2'>
                <span>Company</span>
                <ArrowDownIcon className='h-3'/>
              </div>
            </DropdownMenuTrigger>
            <DropdownMenuContent>
              <DropdownMenuItem>Market</DropdownMenuItem>
              <DropdownMenuItem>Segment</DropdownMenuItem>
              <DropdownMenuItem>Year</DropdownMenuItem>
            </DropdownMenuContent>
          </DropdownMenu>
        </div>
      </div>
      <div className='sm:flex items-center justify-between space-x-2 mt-2'>
        <FilterContext.Provider value={{
          filterState,
          setFilterState: (newFilterState) => {
            setFilterState(newFilterState);
          }
        }}>
          <div className='flex items-center flex-wrap gap-2'>
            <DataTableFacetedFilter columnName='company' title='Company' options={getFacetedData('company')}/>
            <DataTableFacetedFilter columnName='naic' title='NAIC' options={getFacetedData('naic')}/>
            <DataTableFacetedFilter columnName='state' title='State' options={getFacetedData('state')}/>
            <DataTableFacetedFilter columnName='lob' title='LOB' options={getFacetedData('lob')}/>
            <Button
              variant="ghost"
              className="h-8 px-2 lg:px-3 flex items-center"
              onClick={() => setFilterState({})}
            >
              <span>Reset</span>
              <XMarkIcon className="ml-2 h-4 w-4"/>
            </Button>
          </div>
          <div className='sm:block hidden'>
            <DropdownMenu>
              <DropdownMenuTrigger>
                <div className='flex items-center text-sm font-normal space-x-2'>
                  <span>Company</span>
                  <ArrowDownIcon className='h-3' />
                </div>
              </DropdownMenuTrigger>
              <DropdownMenuContent>
                <DropdownMenuItem>Market</DropdownMenuItem>
                <DropdownMenuItem>Segment</DropdownMenuItem>
                <DropdownMenuItem>Year</DropdownMenuItem>
              </DropdownMenuContent>
            </DropdownMenu>
          </div>
        </FilterContext.Provider>
      </div>
      <div className='mt-5 font-normal'>
        <Table />
      </div>
    </div>
  )
}

// type MarketPerformanceData = {}

function Table() {
  const table = useReactTable({
    columns: [
      {
        accessorKey: "company",
        header: 'Company',
        cell: ({getValue}) => {
          return (<div className='whitespace-nowrap'>{getValue()}</div>)
        },
      },
      {
        accessorKey: "naic",
        header: 'NAIC',
      },
      {
        accessorKey: "state",
        header: 'State',
      },
      {
        accessorKey: "lob",
        header: 'LOB',
      },
      {
        accessorKey: "dpeUsdM",
        header: 'DPE USD M',
      },
      {
        accessorKey: "ayIlUsdM",
        header: 'AY IL USD M',
      },
      {
        accessorKey: "ayLr",
        header: 'AY LR',
        cell: (props) => `${Math.round((props.row.original.ayIlUsdM / props.row.original.dpeUsdM) * 100)}%`
      },
      {
        accessorKey: "brokerage",
        header: 'Brokerage',
      },
    ],
    data: tableData,
    manualFiltering: true,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    getFacetedRowModel: getFacetedRowModel(),
    meta: {
      reload: () => {},
      loading: false,
    },
  });

  return (
    <DataTable table={table}/>
  )
}

function downloadFile(filename, content) {
  const blob = new Blob([content], {type: 'text/plain'});
  const link = document.createElement('a');
  const url = URL.createObjectURL(blob);

  link.href = url;
  link.download = filename;

  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);

  URL.revokeObjectURL(url);
}

function DownloadDataButton() {
  const body = 'Welcome to the Fuse Data Preview\n\n\nYou\'re getting an early look at Fuse Data. Please keep in mind, the data is randomly generated each time you open the app, and sorting and filtering features aren’t available yet.';
  return (
    <Button onClick={() => downloadFile('welcome_to_the_fuse_data_preview.txt', body)} variant='link' className='flex items-center space-x-2 p-0' size='sm'>
      <ArrowDownTrayIcon className='h-5' />
      <span>Download</span>
    </Button>
  );
}

function PieChartComponent({setSelectedChart, isSelected, title, className}) {
  const data = useMemo(() => {
    return [
      { browser: "chrome", visitors: generateRandom([20, 300]), fill: "var(--color-chrome)" },
      { browser: "safari", visitors: generateRandom([20, 300]), fill: "var(--color-safari)" },
      { browser: "firefox", visitors: generateRandom([20, 300]), fill: "var(--color-firefox)" },
      { browser: "edge", visitors: generateRandom([20, 300]), fill: "var(--color-edge)" },
      { browser: "other", visitors: generateRandom([20, 300]), fill: "var(--color-other)" },
    ];
  }, []);

  function renderChart() {
    return (
      <ChartContainer
        config={pieChartConfig2}
        className={classNames("", className)}
      >
        <PieChart>
          <Pie data={data} dataKey="visitors" nameKey="browser" />
          <ChartTooltip
            cursor={false}
            content={<ChartTooltipContent hideLabel />}
          />
          <ChartLegend
            content={<ChartLegendContent nameKey="browser" />}
            className="-translate-y-2 flex-wrap gap-2 [&>*]:basis-1/4 [&>*]:justify-center whitespace-nowrap"
          />
        </PieChart>
      </ChartContainer>
    );
  }

  return (
    <Card className={classNames('bg-gray-700', "flex flex-col p-0")}>
      <CardHeader className="items-center p-3">
        <CardTitle className='flex justify-between items-center w-full text-md'>
          <div>{title}</div>
          <div className='flex items-center space-x-4'>
            <Dialog>
              <DialogTrigger asChild>
                <MagnifyingGlassPlusIcon className='h-5' onClick={() => setSelectedChart(title)} />
              </DialogTrigger>
              <DialogContent className='p-5 max-w-2xl'>
                <strong>{title}</strong>
                {renderChart()}
                <div className='flex items-center justify-between'>
                  <DialogDataTableTitle/>
                  <DownloadDataButton/>
                </div>
                <Table/>
              </DialogContent>
            </Dialog>
            <PrinterIcon className='h-5'/>
          </div>
        </CardTitle>
      </CardHeader>
      <CardContent className="p-2">
        {renderChart()}
      </CardContent>
    </Card>
  )
}

const areaChartMinMax = [40, 900];

const areaChartConfig = {
  desktop: {
    label: "2021",
    color: "hsl(var(--chart-1))",
  },
  mobile: {
    label: "2022",
    color: "hsl(var(--chart-2))",
  },
};

function DialogDataTableTitle() {
  return (
    <div className='flex items-center justify-between space-x-2'>
      <strong>Data</strong>
      <div className='bg-purple-500 rounded-full text-xs px-1.5 py-0.5 font-normal'>Illustrative table</div>
    </div>
  );
}

export function AreaChartComponent({setSelectedChart, isSelected, className, title}) {
  const areaChartData = useMemo(() => {
    return [
      {month: "January", desktop: generateRandom(areaChartMinMax), mobile: generateRandom(areaChartMinMax) },
      { month: "February", desktop: generateRandom(areaChartMinMax), mobile: generateRandom(areaChartMinMax) },
      { month: "March", desktop: generateRandom(areaChartMinMax), mobile: generateRandom(areaChartMinMax) },
      { month: "April", desktop: generateRandom(areaChartMinMax), mobile: generateRandom(areaChartMinMax) },
      { month: "May", desktop: generateRandom(areaChartMinMax), mobile: generateRandom(areaChartMinMax) },
      { month: "June", desktop: generateRandom(areaChartMinMax), mobile: generateRandom(areaChartMinMax) },
    ];
  }, []);

  function renderChart() {
    return (
      <ChartContainer config={areaChartConfig} className={className}>
        <AreaChart
          accessibilityLayer
          data={areaChartData}
          margin={{
            left: 12,
            right: 12,
          }}
        >
          <CartesianGrid vertical={false} />
          <XAxis
            dataKey="month"
            tickLine={false}
            axisLine={false}
            tickMargin={8}
            tick={{fill: 'white'}}
            tickFormatter={(value) => value.slice(0, 3)}
          />
          <ChartTooltip
            cursor={false}
            content={<ChartTooltipContent indicator="dot" />}
          />
          <Area
            dataKey="mobile"
            type="natural"
            fill="var(--color-mobile)"
            fillOpacity={0.4}
            stroke="var(--color-mobile)"
            stackId="a"
          />
          <Area
            dataKey="desktop"
            type="natural"
            fill="var(--color-desktop)"
            fillOpacity={0.4}
            stroke="var(--color-desktop)"
            stackId="a"
          />
          <ChartLegend content={<ChartLegendContent/>}/>
        </AreaChart>
      </ChartContainer>
    )
  }

  return (
    <Card
      className={classNames('bg-gray-700', 'flex flex-col p-0')}>
      <CardHeader className='p-3 text-white'>
        <CardTitle className='flex justify-between items-center w-full text-md'>
          <div>{title}</div>
          <div className='flex items-center space-x-4'>
            <Dialog>
              <DialogTrigger asChild>
                <MagnifyingGlassPlusIcon className='h-5' onClick={() => setSelectedChart(title)}/>
              </DialogTrigger>
              <DialogContent className='p-5 max-w-2xl'>
                <strong>{title}</strong>
                {renderChart()}
                <div className='flex items-center justify-between'>
                  <DialogDataTableTitle />
                  <DownloadDataButton/>
                </div>
                <Table/>
              </DialogContent>
            </Dialog>
            <PrinterIcon className='h-5' />
          </div>
        </CardTitle>
      </CardHeader>
      <CardContent className='p-2'>
        {renderChart()}
      </CardContent>
    </Card>
  )
}

const barChartMinMax = [75, 305];

const barChartData = (minMax = barChartMinMax) => {
  console.log('generating bar chart data', minMax)
  return [
    { month: "January", desktop: generateRandom(minMax), mobile: generateRandom(minMax) },
    { month: "February", desktop: generateRandom(minMax), mobile: generateRandom(minMax) },
    { month: "March", desktop: generateRandom(minMax), mobile: generateRandom(minMax) },
    { month: "April", desktop: generateRandom(minMax), mobile: generateRandom(minMax) },
    { month: "May", desktop: generateRandom(minMax), mobile: generateRandom(minMax) },
    { month: "June", desktop: generateRandom(minMax), mobile: generateRandom(minMax) },
  ]
};

const barChartConfig = {
  desktop: {
    label: "2021",
    color: "hsl(var(--chart-1))",
  },
  mobile: {
    label: "2022",
    color: "hsl(var(--chart-2))",
  },
};

export function BarChartComponent({setSelectedChart, isSelected, title, className, minMax}) {
  const data = useMemo(() => {
    return barChartData(minMax);
  }, []);

  function renderChart() {
    return (
      <ChartContainer config={barChartConfig} className={className}>
        <BarChart accessibilityLayer data={data}>
          <CartesianGrid vertical={false} />
          <XAxis
            dataKey="month"
            tickLine={false}
            tickMargin={10}
            axisLine={false}
            tick={{fill: 'white'}}
            tickFormatter={(value) => value.slice(0, 3)}
          />
          <ChartTooltip
            cursor={false}
            content={<ChartTooltipContent indicator="dashed" />}
          />
          <Bar dataKey="desktop" fill="var(--color-desktop)" radius={4} />
          <Bar dataKey="mobile" fill="var(--color-mobile)" radius={4} />
          <ChartLegend content={<ChartLegendContent />} />
        </BarChart>
      </ChartContainer>
    );
  }

  return (
    <Card className={classNames('bg-gray-700', 'flex flex-col p-0')}>
      <CardHeader className='p-3 text-white'>
        <CardTitle className='flex justify-between items-center w-full text-md'>
          <div>{title}</div>
          <div className='flex items-center space-x-4'>
            <Dialog>
              <DialogTrigger asChild>
                <MagnifyingGlassPlusIcon className='h-5' onClick={() => setSelectedChart(title)} />
              </DialogTrigger>
              <DialogContent className='p-5 max-w-2xl'>
                <strong>{title}</strong>
                {renderChart()}
                <div className='flex items-center justify-between'>
                  <DialogDataTableTitle />
                  <DownloadDataButton />
                </div>
                <Table/>
              </DialogContent>
            </Dialog>
            <PrinterIcon className='h-5' />
          </div>
        </CardTitle>
      </CardHeader>
      <CardContent className='p-2 flex-1'>
        {renderChart()}
      </CardContent>
    </Card>
  )
}
