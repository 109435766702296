import {ChartBarIcon, ChatBubbleLeftRightIcon, ChevronDoubleLeftIcon, UsersIcon} from "@heroicons/react/24/outline";
import Tippy from "@tippyjs/react";
import {useState} from "react";
import {useSelector} from "react-redux";
import {Link} from "wouter";
import useAppLocation from "../hooks/useAppLocation";
import Logo from "../Logo";
import UserAvatar from "../UserAvatar";
import {classNames} from "../utils/classes";
import DropdownProfileOptions from "./DropdownProfileOptions";

export default function MiniSidebar() {
  const [hidden, setHidden] = useState(false);
  const {user} = useSelector(state => state.user);
  const {totalUnreadMessages} = useSelector(state => state.global);
  const [activeApp] = useAppLocation();

  return (
    <div className='relative sm:block hidden'>
      {
        !hidden ? (
          <div className='flex flex-col items-center h-full justify-between'>
            <div className='w-[3rem] grow-0 flex flex-col justify-start items-center space-y-3'>
              <div className='scale-[65%] border-b pb-5'>
                <Logo withText={false} />
              </div>
              <Link to='/chat/all'>
                <Tippy placement='right' content='Conversations'>
                  <div
                    className={classNames(activeApp === 'chat' ? 'bg-gray-500' : '', 'flex items-center justify-center hover:bg-gray-500 rounded transition-colors p-1 relative')}>
                    <ChatBubbleLeftRightIcon className='h-6 w-6' />
                    {
                      totalUnreadMessages ? (
                        <div className='rounded-full p-1 text-center bg-fuse-orange absolute -top-1 -right-1 inline text-xs'>
                          <div className='rounded-full h-1.5 w-1.5 bg-white'></div>
                        </div>
                      ) : null
                    }
                  </div>
                </Tippy>
              </Link>
              <Link to='/switchboard/people'>
                <Tippy placement='right' content='People'>
                  <div className={classNames(activeApp === 'switchboard' ? 'bg-gray-500' : '', 'flex items-center justify-center hover:bg-gray-500 rounded transition-colors p-1')}>
                    <UsersIcon className='h-6 w-6'/>
                  </div>
                </Tippy>
              </Link>
              <Link to='/data'>
                <Tippy placement='right' content='Data'>
                  <div className={classNames(activeApp === 'data' ? 'bg-gray-500' : '', 'flex items-center justify-center hover:bg-gray-500 rounded transition-colors p-1')}>
                    <ChartBarIcon className='h-6 w-6'/>
                  </div>
                </Tippy>
              </Link>
            </div>
            <div className='flex flex-col items-center space-y-3 py-3 mb-9'>
              <div className={classNames(activeApp === 'user' ? 'bg-gray-500' : '', 'flex items-center justify-center hover:bg-gray-500 rounded transition-colors p-0.5')}>
                <DropdownProfileOptions contentProps={{side: 'right', align: 'end', alignOffset: -1}}>
                  <div>
                    <UserAvatar firstName={user.first_name} lastName={user.last_name} avatarUrl={user.avatar_url} className='h-6 w-6 rounded' />
                  </div>
                </DropdownProfileOptions>
              </div>
            </div>
          </div>
        ) : null
      }
      <div
        className={classNames(hidden ? '-translate-x-2.5' : '', 'w-50 absolute bottom-2.5 flex justify-center left-2.5 bg-gray-900 p-1 py-2 rounded-r-full transition-transform')}
        onClick={() => setHidden(!hidden)}>
        <ChevronDoubleLeftIcon className={classNames(hidden ? 'rotate-180' : '', 'transition-transform h-4 w-4')}/>
      </div>
    </div>
  )
}
