import {useContext} from "react";
import {Popover, PopoverContent, PopoverTrigger} from "./Popover";
import {Button} from "./Button";
import {PlusCircleIcon} from "@heroicons/react/24/outline";
import {Separator} from "./Separator";
import {Badge} from "./Badge";
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
  CommandSeparator
} from "./Command";
import {CheckIcon} from "@heroicons/react/20/solid";
import * as React from "react";
import {Column} from "@tanstack/react-table";
import { classNames, cn } from "../utils/classes";
import FilterContext from "../Contexts/FilterContext";

interface DataTableFacetedFilterProps<TData, TValue> {
  column?: Column<TData, TValue>
  title?: string
  disabled?: boolean
  options: {
    label: string
    value: string
    icon?: React.ComponentType<{ className?: string }>
    count?: number
  }[]
}

export default function DataTableFacetedFilter<TData, TValue>({
  column,
  title,
  options,
  disabled,
  columnName,
}: DataTableFacetedFilterProps<TData, TValue> & {columnName?: string}) {
  const {filterState, setFilterState} = useContext(FilterContext);
  const selectedValues = new Set(filterState[columnName] as string[]);


  function getDisplayValue(value: boolean | string): string {
    if (typeof value === 'boolean') {
      value = value ? 'Yes' : 'No';
    }

    return value;
  }
  console.log(title, selectedValues.size);

  return (
    <Popover>
      <PopoverTrigger asChild>
        <Button disabled={disabled} variant="outline" size="sm" className={classNames(selectedValues.size ? 'border-solid' : 'border-dashed', 'h-7')}>
          <PlusCircleIcon className="mr-2 h-4 w-4" />
          {title}
          {
            selectedValues?.size > 0 && (
              <>
                <Separator orientation="vertical" className="mx-2 h-4" />
                <Badge
                  variant="secondary"
                  className="rounded-sm px-1 font-normal lg:hidden"
                >
                  {selectedValues.size}
                </Badge>
                <div className="hidden space-x-1 lg:flex">
                  {selectedValues.size > 2 ? (
                    <Badge
                      variant="secondary"
                      className="rounded-sm px-1 font-normal"
                    >
                      {selectedValues.size} selected
                    </Badge>
                  ) : (
                    options
                    .filter((option) => selectedValues.has(option.value))
                    .map((option) => {
                      const value = getDisplayValue(option.value);
                      console.log('printing selected', option)

                      return (
                        <Badge
                          variant="secondary"
                          key={`${title}_${option.value}`}
                          className="rounded-sm px-1 font-normal"
                        >
                          {value}
                        </Badge>
                      )
                    })
                  )}
                </div>
              </>
            )
          }
        </Button>
      </PopoverTrigger>
      <PopoverContent className="w-[200px] p-0" align="start">
        <Command>
          <CommandInput placeholder={title} />
          <CommandList>
            <CommandEmpty>No results found.</CommandEmpty>
            <CommandGroup>
              {options.map((option) => {
                let {value} = option;
                const isSelected = selectedValues.has(option.value);

                value = getDisplayValue(value);

                return (
                  <CommandItem
                    key={`${columnName}_${option.value}`}
                    onSelect={() => {
                      if (isSelected) {
                        selectedValues.delete(option.value);
                      } else {
                        selectedValues.add(option.value);
                      }

                      setFilterState({...filterState, [columnName]: Array.from(selectedValues)});
                    }}
                  >
                    <div
                      className={cn(
                        "mr-2 flex h-4 w-4 items-center justify-center rounded-sm border border-primary",
                        isSelected ? "bg-primary text-primary-foreground" : "opacity-50 [&_svg]:invisible"
                      )}
                    >
                      <CheckIcon className={cn("h-4 w-4")}/>
                    </div>
                    {option.icon && (
                      <option.icon className="mr-2 h-4 w-4 text-muted-foreground"/>
                    )}
                    <span className='truncate' title={value}>{value}</span>
                    <span className="ml-auto flex h-4 w-4 items-center justify-center font-mono text-xs">{option.count}</span>
                  </CommandItem>
                )
              })}
            </CommandGroup>
            {selectedValues.size > 0 && (
              <>
                <CommandSeparator />
                <CommandGroup>
                  <CommandItem
                    onSelect={() => setFilterState({})}
                    className="justify-center text-center"
                  >
                    Clear filters
                  </CommandItem>
                </CommandGroup>
              </>
            )}
          </CommandList>
        </Command>
      </PopoverContent>
    </Popover>
  )
}
