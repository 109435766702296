import {FC, ReactNode, useContext, useEffect, useState} from "react";
import DashboardContext from '../Contexts/DashboardContext';
import * as React from "react"
import {Profile, FilterState} from './types';
import { Button } from '../ui/Button';
import {
  getCoreRowModel,
  getSortedRowModel,
  SortingState,
  useReactTable,
  VisibilityState,
  getFilteredRowModel,
  getPaginationRowModel,
  getFacetedRowModel,
  getFacetedUniqueValues,
  RowData,
  PaginationState,
} from "@tanstack/react-table";
import {
  Bars3Icon,
  ChevronDoubleLeftIcon,
  ChevronDoubleRightIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
} from "@heroicons/react/24/outline";
import useSocket from "../hooks/useSocket";
import DataTableToolbar from "../ui/DataTableToolbar";
import useAppSelector from "../hooks/useAppSelector";
import FilterContext from '../Contexts/FilterContext';
import DataTable from "../ui/DataTable";
import getColumns from "./columns";
import {toast} from "react-toastify";
import { DEFAULT_TOAST_CONFIG } from "../utils/toast";
import {useTitle} from "react-use";

declare module '@tanstack/table-core' {
  interface TableMeta<TData extends RowData> {
    reload: () => void
    loading: boolean
  }
}

declare module '@tanstack/react-table' {
  interface ColumnMeta<TData extends RowData, TValue> {
    displayName?: string
    hidden?: boolean
    adminOnly?: boolean
  }
}

const initialColumnVisibility = {lobSpecialty: false};

export default function Switchboard({title, type = 'people', ...props}: {title: string, icon: ReactNode, type?: string}) {
  const [profiles, setProfiles] = useState([]);
  const [columnVisibility, setColumnVisibility] = useState<VisibilityState>(initialColumnVisibility);
  const [toolbarFilters, setToolbarFilters] = useState({});
  const [filterState, setFilterState] = useState<any>({});
  const [sortingState, setSortingState] = useState<SortingState>([]);
  const [paginationState, setPaginationState] = useState<PaginationState>({ pageIndex: 0, pageSize: 10 });
  const [pageCount, setPageCount] = useState<number>(1);
  const [loadingProfiles, setLoadingProfiles] = useState<boolean>(false);
  const {user} = useAppSelector(state => state.user);
  const {toggleShowSidebar} = useContext(DashboardContext) as Partial<{toggleShowSidebar: () => void}>;
  const [socket] = useSocket();

  useTitle('Fuse - People');

  useEffect(() => {
    function restoreUrlState() {
      const url = new URL(window.location.href);
      const search = url.searchParams.get('query');

      if (!search) return;

      try {
        const {filterState: searchFilterState, paginationState, sortingState} = JSON.parse(search);
        if (searchFilterState) setFilterState(searchFilterState);
        if (paginationState) setPaginationState(paginationState);
        if (sortingState) setSortingState(sortingState);
      } catch (error) {
        console.debug('Could not parse query string');
      }
    }

    restoreUrlState();

    window.addEventListener('popstate', restoreUrlState);

    return () => {
      window.removeEventListener('popstate', restoreUrlState);
    }
  }, []);

  useEffect(persistTableState, [sortingState, filterState, paginationState])
  useEffect(loadProfiles, [socket, sortingState, filterState, paginationState]);

  function resetPagination() {
    setPaginationState({...paginationState, pageIndex: 0});
  }

  const table = useReactTable({
    columns: getColumns(user),
    data: profiles,
    meta: {
      reload: loadProfiles,
      loading: loadingProfiles,
    },
    state: {
      sorting: sortingState,
      columnVisibility,
      pagination: paginationState,
      columnPinning: {
        right: ['actions'],
      }
    },
    pageCount,
    manualSorting: true,
    manualFiltering: true,
    manualPagination: true,
    onSortingChange: setSortingState,
    onColumnVisibilityChange: setColumnVisibility,
    onPaginationChange: setPaginationState,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
  });

  function persistTableState() {
    const filterStateJson = JSON.stringify({sortingState, filterState, paginationState});

    const url = new URL(window.location.href);
    url.searchParams.set('query', filterStateJson);
    window.history.pushState({}, '', url);
  }

  function loadProfiles() {
    if (!socket?.connected || !user || loadingProfiles) return;

    setLoadingProfiles(true);

    socket.emit('profiles:get', {
      type,
      sorting: sortingState,
      columnFilters: Object.entries(filterState),
      pagination: paginationState,
    }, ({success, profiles, pageCount, columnFilters}) => {
      setLoadingProfiles(false);

      if (!success) {
        toast.error('Couldn\'t load the contacts at this time. Try again!', DEFAULT_TOAST_CONFIG);
        return;
      }

      const profilesWithConnections = profiles.map((profile: Profile): Profile => {
        const claimPending = profile.userProfileId && !profile.claimed;
        const isOwn = profile.id === user.id;

        return {
          ...profile,
          meta: {
            isOwn,
            canInteract: profile.claimed || !profile.connected || !isOwn,
            claimPending,
          }
        } as Profile;
      });

      setProfiles(profilesWithConnections);
      setToolbarFilters(columnFilters);
      setPageCount(pageCount);
    });
  }

  const pageIndex = table.getState().pagination.pageIndex + 1;

  return (
    <FilterContext.Provider value={{
      filterState,
      toggleSorting: (column) => {
        column.toggleSorting();
        resetPagination();
      },
      setFilterState: (newFilterState: FilterState) => {
        setFilterState(newFilterState);
        resetPagination();
      }
    }}>
      <div className='flex flex-col justify-between sm:sticky top-0 z-50 bg-gray-800 text-lg font-semibold text-gray-200 py-5 px-4'>
        <div className='flex items-center pb-4'>
          <div className='lg:hidden lg:mr-0 mr-3 hidden' onClick={toggleShowSidebar}>
            <span className="sr-only">Open sidebar</span>
            <Bars3Icon role='button' className="h-7 w-7" aria-hidden="true"/>
          </div>
          <div className='flex items-center'>
            <div className='flex items-center space-x-2'>
              <div className='[&>svg]:h-5 xl:[&>svg]:h-5'>{props.icon}</div>
              <div>{title}</div>
            </div>
          </div>
        </div>
        <DataTableToolbar columnFilters={toolbarFilters} table={table}/>
      </div>
      <div className='space-y-5 px-4'>
        <DataTable table={table}/>
        <div className="flex items-center justify-between pb-4 space-x-6 lg:space-x-8 px-4">
          <div className="w-[100px] text-sm font-medium">
            {`Page ${pageIndex} of ${Math.max(pageIndex, table.getPageCount())}`}
          </div>
          <div className="flex items-center space-x-2">
            <Button
              variant="outline"
              className="hidden h-8 w-8 p-0 lg:flex"
              onClick={() => table.setPageIndex(0)}
              disabled={!table.getCanPreviousPage()}
            >
              <span className="sr-only">Go to first page</span>
              <ChevronDoubleLeftIcon className="h-4 w-4"/>
            </Button>
            <Button
              variant="outline"
              className="h-8 w-8 p-0"
              onClick={() => table.previousPage()}
              disabled={!table.getCanPreviousPage()}
            >
              <span className="sr-only">Go to previous page</span>
              <ChevronLeftIcon className="h-4 w-4"/>
            </Button>
            <Button
              variant="outline"
              className="h-8 w-8 p-0"
              onClick={() => table.nextPage()}
              disabled={!table.getCanNextPage()}
            >
              <span className="sr-only">Go to next page</span>
              <ChevronRightIcon className="h-4 w-4"/>
            </Button>
            <Button
              variant="outline"
              className="hidden h-8 w-8 p-0 lg:flex"
              onClick={() => table.setPageIndex(table.getPageCount() - 1)}
              disabled={!table.getCanNextPage()}
            >
              <span className="sr-only">Go to last page</span>
              <ChevronDoubleRightIcon className="h-4 w-4"/>
            </Button>
          </div>
        </div>
      </div>
    </FilterContext.Provider>
  );
}
