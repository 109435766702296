import {useMemo} from 'react';

export function DaysAreDifferent({start, end, children}) {
  if ((start && end && new Date(start).getDate() !== new Date(end).getDate()) || (!!start && !end)) {
    return children;
  }

  return null;
}

export default function DateFormatter({dateString, options}) {
  return useMemo(() => dateFormatter(dateString, options), []);
}

export function dateFormatter(dateString, options) {
  if (!dateString) return '';

  return new Intl.DateTimeFormat("en-US", options || {
    year: "2-digit",
    month: "2-digit",
    day: "2-digit",
    hour: 'numeric',
    minute: 'numeric',
  })
  .format(new Date(dateString));
}
