import {Table as TanTable} from "@tanstack/table-core/build/lib/types";
import {Profile} from "../Switchboard/types";
import {Table, TableBody, TableCell, TableHead, TableHeader, TableRow} from "./Table";
import {getCommonPinningStyles} from "../Switchboard/utils";
import {flexRender} from "@tanstack/react-table";
import {Button} from "./Button";
import Spinner from '../Spinner';
import * as React from "react";
import {useLocation} from "wouter";

export default function DataTable<TData, TValue>({table}: {table: TanTable<Profile>}) {
  const [, navigate] = useLocation();

  return (
    <div className="rounded-md border overflow-x-scroll">
      <Table>
        <TableHeader>
          {table.getHeaderGroups().map((headerGroup) => (
            <TableRow className='bg-gray-800' key={headerGroup.id}>
              {headerGroup.headers.map((header) => {
                const {column} = header;

                if (column.columnDef.meta?.hidden) return null;

                return (
                  <TableHead className='whitespace-nowrap' key={header.id} style={{ ...getCommonPinningStyles(column) }}>
                    {
                      header.isPlaceholder
                        ? null
                        : flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )
                    }
                  </TableHead>
                )
              })}
            </TableRow>
          ))}
        </TableHeader>
        <TableBody className='h-full overflow-auto'>
          {table.getRowModel().rows?.length ? (
            table.getRowModel().rows.map((row) => {
              const visibleCells = row.getVisibleCells();
              const lastIdx = visibleCells.length - 1;

              return (
                <TableRow
                  key={row.id}
                  className='hover:bg-green-500/10 bg-gray-800 cursor-pointer'
                  data-state={row.getIsSelected() && "selected"}
                >
                  {visibleCells.map((cell, cellIdx) => {
                    const {column} = cell;

                    if (column.columnDef.meta?.hidden) return null;

                    return (
                      // @ts-ignore
                      <TableCell onClick={() => cellIdx !== lastIdx && row.original?.id && navigate(`/people/${row.original.id}`)} style={{...getCommonPinningStyles(column)}} key={cell.id}>
                        {flexRender(cell.column.columnDef.cell, cell.getContext())}
                      </TableCell>
                    )
                  })}
                </TableRow>
              )
            })
          ) : (
            <TableRow>
              <TableCell colSpan={table.getAllColumns().length} className="h-48 text-center space-y-5">
                {
                  table.options.meta.loading ? (
                    <div className='flex items-center justify-center space-x-2'>
                      <Spinner className='h-5' />
                      <span>Loading...</span>
                    </div>
                  ) : (
                    <>
                      <div>No results.</div>
                      <Button size='sm' onClick={table.options.meta.reload}>Refresh</Button>
                    </>
                  )
                }
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </div>
  )
}
